import { readonly, ref, computed, reactive } from 'vue'
import { Rewards, Prizes, Transactions, CartItem, PrizeRedeemResponse } from '../middlewares/auth/models'
import { useAuth } from './useAuth'
import { HttpClient } from '../utils';
import useAppSetup from './useAppSetup';
import i18n from '../i18n';
import GAnalytics from "../utils/GAnalytics"
// import GrandPrizeImage from '../assets/img/grand_prize.png';
const { authToken, authUser } = useAuth();
const { appToken, selectedLanguage } = useAppSetup();
const { t } = i18n.global;

const httpClient = new HttpClient(import.meta.env.VITE_AUTH_API_URL).httpClient;

// const initSelectedReward: Rewards = {
//     id: 0,
//     name: '',
//     desc: '',
//     sku: '',
//     image_url: '',
//     total_qty: 0,
//     total_claimed: 0,
//     points_required: 0,
//     reward_type: '',
//     retail_value: 0,
//     shipping_required: false,
//     shipping_cost: 0,
//     sort_order: 0,
//     is_available: true,
// }

// const initSelectedPrize: Prizes = {
//     id: 0,
//     name: '',
//     desc: '',
//     image_url: '',
//     total_qty: 0,
//     total_claimed: 0,
//     points_required: 0,
//     provider: '',
//     config: {
//         locales: [
//             {
//                 locale: '',
//                 scratch_card_image: '',
//             }
//         ],
//         prizing_id: '',
//         reveal_type: '',
//     },
//     locales: null,
//     is_enabled: false,
// }

const initTransactions: Transactions = {
    total_results: 0,
    total_pages: 0,
    current_page: 0,
    per_page: 0,
    results: [{
        tx_key: '',
        points_earned: 0,
        points_redeemed: 0,
        created_at: new Date(),
        transaction_details:
            [{
                tx_type: 'earn',
                details: {
                    tab: '',
                    action: '',
                },
                points: 0,
                notes: '',
            }]
    }]
}

const availableRewards = ref<Rewards[]>()
const availablePrizes = ref<Prizes[]>()
const selectedReward = ref<Rewards | Prizes | undefined>()
const selectedPrize = ref<Prizes>()
const transactions = ref<Transactions>(initTransactions)
const cartItems = ref<CartItem[]>()
// const grandPrize = reactive<Rewards>({
//     id: 0,
//     name: 'Grand Prize Draw',
//     desc: 'Each tab code gives you a chance to win 1 of 10 Monster Energy chairs. Chairs may not appear exactly as shown.',
//     sku: 'grandprize-123-123',
//     image_url: GrandPrizeImage,
//     total_qty: 1,
//     total_claimed: 1,
//     points_required: 10000000000,
//     reward_type: 'grand-prize',
//     retail_value: 0,
//     shipping_required: false,
//     shipping_cost: 0,
//     sort_order: 9999,
//     is_available: true,
//     locales:
//        [{
//           locale: 'fr',
//           name: 'Tirage du Grand Prix',
//           desc: 'Chaque code d’onglet vous donne une chance de gagner 1 des 10 chaises Monster Energy',
//           image_url: GrandPrizeImage
//        }],
//  });
type shippingAddress = {
    shipping_address: {
        address_line_1: string;
        address_line_2: string | null;
        city: string;
        postal_code: string;
        province: string;
        country: string;
    }

}
type rewardPurchased = {
    reward_name: string;
    reward_code: string;
}

export function useRewards() {
    const rewardSkuFrenchNameMapper = ref<Record<string,string>>({})
    const setRewards = async () => {
        let page=1
        let limit=500
        const result = await httpClient.get<{ payload: Rewards[] }>(`/v1/clients/loyalty/rewards?page=${page}&limit=${limit}`, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            throw new Error(result.msg);
        }
        availableRewards.value = result.payload.sort((a,b) => a.sort_order - b.sort_order)
    }

    const setPrizes = async () => {
        const result = await httpClient.get<{ payload: Prizes[] }>(`/v1/clients/loyalty/prizes`, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            throw new Error(result.msg);
        }
        availablePrizes.value = result.payload
    }

    const addItemToCart = async (rewardId: number|string) => {
        const result = await httpClient.post<{}>(`/v1/clients/loyalty/cart/items`, {
            reward_id: rewardId
        }, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });
        if (result.error) {
            // throw new Error(result.msg);
            return 
        }

        GAnalytics.logCustomEvent('add_to_cart', {
            reward_id: rewardId.toString(),
        })
        // await getMyCart()
    }

    const redeemPrize = async (prizeId: number) => {
        const result = await httpClient.post<PrizeRedeemResponse>(`/v1/clients/loyalty/redeem/prize`, {
            prize_id: prizeId
        }, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });
        if (result.error) {
            throw new Error(result.msg);
        }

        console.log(result)
        let prize = {
            ...result.prize,
            isPrizeRevealed: false
        }
        console.log('request', prize.isPrizeRevealed)
        window.localStorage.setItem('redeemedPrize', JSON.stringify(prize))

    }

    const setSelectedReward = (reward: Rewards | Prizes | undefined) => {
        selectedReward.value = reward
    }

    const setSelectedPrize = (prize: Prizes) => {
        selectedPrize.value = prize
    }

    const claimReward = async (keyword: string) => {
        if (authUser.lifetime_points >= 160 || availableRewards.value && authUser.lifetime_points >= availableRewards.value?.length) {
            throw new Error(t("balance_reached_message"))
        }
        const result = await httpClient.post<{}>(`/v1/clients/keywords/claim`, {
            keyword
        }, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });
        if (result.statusCode === 400) {
            throw new Error(t("entered_pin_invalid"));
        } else if (result.error) {
            throw new Error(t("generic_server_error"));
        }
        console.log(result)
    }

    const getTransactions = async (page: number = 1, limit: number = 100, redeemonly: boolean = true) => {
        const result = await httpClient.get<Transactions>(`/v1/clients/loyalty/transactions?page=${page}&limit=${limit}&redeemonly=${redeemonly}`, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            // throw new Error(result.msg);
            console.log(result.error)
            return
        }

        if (result.current_page === 1) {
            Object.assign(transactions.value, result)
        } else {
            let oldTransaction = transactions.value.results
            Object.assign(transactions.value, result)
            transactions.value.results = [...oldTransaction, ...result.results]
        }

    }

    const setCartItems = (data: CartItem[]) => {
        cartItems.value = data
    }


    const getMyCart = async () => {
        const result = await httpClient.get<{ payload: CartItem[] | [] }>(`/v1/clients/loyalty/cart`, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            throw new Error(result.msg);
            // console.log(result.msg)
        }

        if (result.payload.length > 0) {
            setCartItems(result.payload)
        } else if (result.payload.length === 0) {
            setCartItems([])
        }
    }

    const removeCartItem = async (cartItemId: number) => {
        const result = await httpClient.delete<{}>(`/v1/clients/loyalty/cart/items/${cartItemId
            }`, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            throw new Error(result.msg);
        }

        if (!cartItems.value) return
        const index = cartItems.value.findIndex(el => el.id === cartItemId)

        if (index > -1) {
            let newCartItems = [...cartItems.value]
            newCartItems.splice(index, 1)
            setCartItems(newCartItems)
        }
        GAnalytics.logCustomEvent('remove_item', {
            reward_id: cartItemId.toString(),
        })
    }

    const updateCartItemQty = async (cartItemId: number, qty: number) => {
        const result = await httpClient.patch<{}>(`/v1/clients/loyalty/cart/items/${cartItemId}`, {
            qty
        }, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            throw new Error(result.msg);
        }
        if (!cartItems.value) return
        const index = cartItems.value.findIndex(el => el.id === cartItemId)
        let newCartItems: CartItem[] = [...cartItems.value]
        newCartItems[index].qty = qty
        setCartItems(newCartItems)
        GAnalytics.logCustomEvent('remove_from_cart', {
            reward_id: cartItemId.toString(),
        })
    }
    const helperSkuRewardNameMapper = () => {
        availableRewards.value?.forEach(reward => {
            rewardSkuFrenchNameMapper.value[reward.sku] = ''
            if(reward.locales) {
                reward.locales.forEach(locale => {
                    if(locale.locale === 'fr') {
                        rewardSkuFrenchNameMapper.value[reward.sku] = locale.name
                    }
                })
            }
        })
    }
    const rewardsPurchasedByUser = computed<rewardPurchased[]>(()=> {
        helperSkuRewardNameMapper()
        let rewards:rewardPurchased[] = []
        transactions.value.results.forEach((transaction) => {
            transaction.transaction_details.forEach((tx_details)=> {
                if (tx_details.tx_type === 'redeem' && tx_details.details.reward_code) {
                    let codeArr: string[] = tx_details.details.reward_code.split(',')
                    codeArr.forEach(code => {
                        if(selectedLanguage.value === 'fr' && rewardSkuFrenchNameMapper.value[tx_details.details.reward_sku] !== '') {
                            rewards.push({reward_code: code, reward_name: rewardSkuFrenchNameMapper.value[tx_details.details.reward_sku]})
                        } else {
                            rewards.push({reward_code: code, reward_name: tx_details.details.reward_name})
                        }
                    })
                    // rewards.push({reward_code: tx_details.details.tab, reward_name: tx_details.details.reward_name})
                }
            })
        })
        return rewards;
    })

    const totalNoOfCartItems = computed<number>(() => {
        if (!cartItems.value) return 0

        let sum = 0
        cartItems.value.forEach(item => {
            sum += item.qty
        })
        return sum
    })

    const totalCartsPoints = computed<number>(() => {
        if (!cartItems.value) return 0
        let sum = 0
        cartItems.value.forEach(item => {
            sum += item.qty * item.reward?.points_required
        })
        return sum
    })

    const userCurrentPoints = computed<number>(() => {
        if (!cartItems.value) return authUser.points
        let totalCartsPoints = 0
        cartItems.value.forEach(item => {
            totalCartsPoints += item.qty * item.reward?.points_required
        })
        return authUser.points - totalCartsPoints
    })

    const checkout = async (data: {} | shippingAddress) => {
        const result = await httpClient.post<{}>(`/v1/clients/loyalty/cart/checkout`, data, {
            headers: {
                'app-token': appToken.value,
                'auth-token': authToken.value,
            }
        });

        if (result.error) {
            // throw new Error(t('checkout_failed'));
            return
        }
        // await getMyCart()
    }

    return {
        setRewards,
        setPrizes,
        setSelectedReward,
        setSelectedPrize,
        // grandPrize,
        selectedReward,
        selectedPrize,
        availableRewards,
        availablePrizes,
        addItemToCart,
        redeemPrize,
        claimReward,
        transactions,
        getTransactions,
        getMyCart,
        setCartItems,
        cartItems,
        totalNoOfCartItems,
        totalCartsPoints,
        userCurrentPoints,
        removeCartItem,
        updateCartItemQty,
        checkout,
        rewardsPurchasedByUser
    }
}