<template>
	<div
		class="w-full h-8 text-nav-primary-inactive text-sm bg-nav-primary flex items-center"
	>
		<a
			:href="termsSite"
			target="_blank"
			class="m-auto"
			aria-label="open terms and conditions in new tab"
			>{{ $t('agreement_terms_amp') }}</a
		>
	</div>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import useAppSetup from '../../composables/useAppSetup';

const { selectedLanguage } = useAppSetup();

const termsSite = computed(() =>
	selectedLanguage.value === 'fr'
		? 'https://www.monsterenergyrules.com/COD/FR'
		: 'https://www.monsterenergyrules.com/COD/EN'
);
</script>

<style></style>
